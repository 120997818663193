<template>
  <div class="course-filter" id="app" >
    <!-- <el-header>
      <div class="cur-nav">
        <span>沙发智造工学院</span>
        <i class="el-icon-close"></i>
      </div>
    </el-header> -->
    <el-main>
      <!-- 筛选部分 -->
      <div class="filter-con-w" >
        <div class="filter-icon">
          <div class="filter-h-w" @click="filterUpDown">
            <div class="filter-h" v-if="filterIsShow">
              <span>收起筛选</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="filter-h" v-else>
              <span>展开筛选</span>
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
          <el-collapse-transition>
            <div class="filter-b" v-show="filterIsShow">
              <!-- <div class="filter-classify-lis">
                <div class="filter-title">学员级别：</div>
                <div
                  class="filter-icon-txt"
                  @click="studentLevelAll"
                  :class="{ cur: studentLevelAllCur == 1 }"
                >
                  全部
                </div>
                <div
                  class="filter-icon-txt"
                  v-for="(item, index) in studentLevel"
                  :key="index"
                  @click="stutentLevelChoice(item.personnelLevelId)"
                  :class="{ cur: item.personnelLevelId == personnelLevelId }"
                >
                  {{ item.personnelLevelName }}
                </div>
              </div> -->
              <div class="filter-classify-lis">
                <div class="filter-title">类型：</div>
                <div
                  class="filter-icon-txt"
                  @click="projectLabelAll"
                  :class="{ cur: projectLabelAllCur == 1 }"
                >
                  全部
                </div>
                <div
                  class="filter-icon-txt"
                  v-for="(item, index) in projectLabel"
                  :key="index"
                  :class="{ cur: item.id == projectLabelId }"
                  @click="projectLabelChoice(item.id)"
                >
                  {{ item.title }}
                </div>
              </div>
              <div class="filter-classify-lis">
                <div class="filter-title">车间：</div>
                <div
                  class="filter-icon-txt"
                  @click="structureAll"
                  :class="{ cur: structureAllCur == 1 }"
                >
                  全部
                </div>
                <div
                  class="filter-icon-txt"
                  v-for="(item, index) in structure"
                  :key="index"
                  :class="{ cur: structureId == item.structureId }"
                  @click="structureChoice(item.structureId)"
                >
                  {{ item.structureName }}
                </div>
              </div>
              <!-- <div class="filter-classify-lis">
                <div class="filter-title">专题：</div>
                <div
                  class="filter-icon-txt"
                  @click="topicAll"
                  :class="{ cur: topicAllCur == 1 }"
                >
                  全部
                </div>
                <div
                  class="filter-icon-txt"
                  v-for="(item, index) in topicTit"
                  :key="index"
                  :class="{ cur: topic === item.id }"
                  @click="topicChoice(item.id)"
                >
                  {{ item.title }}
                </div>
              </div> -->
              <div class="filter-sort">
                <div class="sort-title">排序：</div>
                <div class="sort-icon">
                  <div
                    class="sort-new"
                    @click="sortNew(1)"
                    :style="{ color: sort === 1 ? '#2189f3' : '' }"
                  >
                    <span>最新</span>
                    <i
                      class="el-icon-d-caret"
                      :style="{ color: sort === 1 ? '#2189f3' : '' }"
                    ></i>
                    <!-- <div class="new-icon">
                      <i class="el-icon-caret-top"></i>
                      <i class="el-icon-caret-bottom"></i>
                    </div> -->
                  </div>
                  <div
                    class="sort-hot"
                    @click="sortNew(0)"
                    :style="{ color: sort == 0 ? '#2189f3' : '' }"
                  >
                    <span>最热</span>
                    <i
                      class="el-icon-d-caret"
                      :style="{ color: sort == 0 ? '#2189f3' : '' }"
                    ></i>
                    <!-- <div class="hot-icon">
                      <i class="el-icon-caret-top"></i>
                      <i class="el-icon-caret-bottom"></i>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>
      <!-- 视频列表 -->
      <div class="video-detail" @scroll.passive="getScroll($event)" :style="{ height: screenHeight - 280 + 'px' }">
        <div class="video-detail-h">
          <span>共{{ courseNumInfo.videoNumber }}个视频</span>
          <span>{{ courseNumInfo.projectNumber }}个专题</span>
        </div>
        <!-- <div class="video-lis" v-infinite-scroll="loadMore" infinite-scroll-distance="200"> -->
        <div class="video-lis">
          <div
            class="video-item"
            v-for="(item, index) in courseList"
            :key="index"
            @click="goDetails(item.courseId, item.topic)"
            @mouseenter="lookShow(index)"
            @mouseleave="lookLeave(index)"
          >
            <!-- <div class="video-img" >
              <el-image :src="item.courseCover" lazy></el-image>
              <transition name="el-fade-in-linear">
                <div
                  class="look-num-w"
                  v-show="lookIsShow && index == lookCurIndex"
                >
                  <div class="look-num">{{ item.learningNumber }}人看过</div>
                </div>
              </transition>
            </div> -->
            
            <div style="position: relative;"><el-image :src="item.courseCover" lazy></el-image>
              <div class="qqq" >
                <transition name="el-fade-in-linear" v-show="lookIsShow && index == lookCurIndex">
                  <div class="look-num-q" v-show="lookIsShow && index == lookCurIndex">
                    <div class="look-num" >{{ item.learningNumber }}人看过</div>
                  </div>
                </transition>
              </div>
            </div>
            <div class="video-txt">
              <div class="video-h">
                <span class="video-h-txt">{{ item.courseTitle }}</span>
                <!-- <span class="video-ico" v-show="item.topic == 1">专题</span> -->
                <span class="topic-ico" v-if="item.topic == 1">专题</span>
                <span class="video-ico" v-else>视频</span>
              </div>
              <div class="video-intro">
                {{ item.courseIntroduction }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="pagenation">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            background
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div> -->
      </div>
    </el-main>
  </div>
</template>
<script>
import {
  coursePage,
  courseNumber,
  personnelLevelList,
  structureList,
  projectLabelList,
} from "@/api/course/courseFilter.js";

export default {
  data() {
    return {
      filterIsShow: true, //展示筛选选项
      lookIsShow: false, //多少人看过窗口显示
      lookCurIndex: 0, //多少人看过窗口当前index
      filterTits: ["学员级别：", "类型：", "车间：", "筛选：", "排序："],
      // 类型
      studentLevel: [], //学员级别
      projectLabel: [],
      studentLevelAllCur: 1, //类型全部是否选择,0未选——1选中
      projectLabelAllCur: 1, //类型全部是否选择,0未选——1选中
      // 车间
      structure: [],
      structureAllCur: 1, //类型全部是否选择,0未选——1选中
      // 专题
      topicTit: [
        {
          id: 1,
          title: "仅专题",
        },
        {
          id: 0,
          title: "仅视频",
        },
      ],
      topicAllCur: 1, //筛选全部是否选择,0未选——1选中
      pageNum: 1, //当前页码
      pageSize: 8, //每页数量
      personnelLevelId: "", //学员级别ID
      projectLabelId: "", //课程标签id
      sort: "", //	课程排序0最新1最热
      structureId: "", //车间ID
      topic: "", //是否为专题 1——专题，0——视频
      courseNumInfo: {}, //课程数量

      courseList: [], //课程列表
      total: 0, //总条数
      totalPage: 0, //总页码
      listDataFlag: false,
      loading: false,
      screenHeight: document.documentElement.clientHeight, //获取屏幕宽度
    };
  },
  // mounted(){

  // },
  created() {
    this.listDataFlag = false;
    this.getCoursePage();
    this.getCourseNumber();
    this.getPersonnelLevelList();
    this.getStructureList();
    this.toBottom();
    this.getProjectLabelList();


  },
  mounted() {
    // window.addEventListener('scroll', this.toBottom, true);  // 监听（绑定）滚轮滚动事件
 
  },


  methods: {
    getScroll(e) {
      if(e.target.clientHeight + e.target.scrollTop == e.target.scrollHeight) {
        this.pageNum++;
        this.getCoursePage();
      }
    },
 


    // 测试
    // test(){
    //   this.$axios({ method:'get',
    //             url:'http://192.168.5.11:31013/base/baseProjectLabel/personnelLevelList'}).then((res)=>{
    //               console.log(res);
    //             }).catch((err)=>{
    //               console.log(err)
    //             })
    // },
    // 点击收起筛选
    filterUpDown() {
      this.filterIsShow = !this.filterIsShow;
    },
    // 学员级别选择
    stutentLevelChoice(index) {
      this.courseList = [];
      this.personnelLevelId = index;
      this.studentLevelAllCur = 0;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },

    //学员类型全选
    studentLevelAll() {
      this.courseList = [];
      this.personnelLevelId = "";
      this.studentLevelAllCur = 1;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 类型选择
    projectLabelChoice(index) {
      this.courseList = [];
      this.projectLabelId = index;
      this.projectLabelAllCur = 0;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },

    // 类型全部选择
    projectLabelAll() {
      this.courseList = [];
      this.projectLabelId = "";
      this.projectLabelAllCur = 1;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 车间全部选择
    structureAll() {
      this.courseList = [];
      this.structureId = "";
      this.structureAllCur = 1;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 车间选择
    structureChoice(index) {
      this.courseList = [];
      this.structureId = index;
      this.structureAllCur = 0;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 筛选选择
    topicChoice(index) {
      this.courseList = [];
      this.topic = index;
      this.topicAllCur = 0;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 筛选全部
    topicAll() {
      this.courseList = [];
      this.topic = "";
      this.topicAllCur = 1;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    //最新
    sortNew(value) {
      this.courseList = [];
      this.sort = value;
      this.pageNum = 1;
      this.getCoursePage();
      this.getCourseNumber();
    },
    // 多少人看过
    lookShow(index) {
      this.lookIsShow = true;
      this.lookCurIndex = index;
    },
    lookLeave(index) {
      this.lookIsShow = false;
      this.lookCurIndex = index;
    },
    // loadMore(){
    //   this.pageNum++;
    //   this.getCoursePage();

    // },

    // 到底部
    toBottom() {
      let _this = this;
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
        
      //变量windowHeight是可视区的高度
      var windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      var scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      console.log(scrollTop + windowHeight,scrollHeight)
      let q = document.getElementById('app').scrollHeight
      console.log(q,'scrollTop')
      //滚动条到底部的条件
      if (scrollTop + windowHeight + 40 >= scrollHeight) {
        if (this.listDataFlag) {
          return false;
        }
        this.listDataFlag = true;
        //到了这个就可以进行业务逻辑加载后台数据了
        console.log("到了底部");
        _this.pageNum++;
       console.log(_this.pageNum,'1232')
        _this.getCoursePage();
      }
    },

    //课程详情
    goDetails(id, topic) {
      if (1 == topic) {
        this.$router.push({
          path: "/workbench/specialCourse",
          query: {
            courseId: id,
          },
        });
      } else if (0 == topic) {
        this.$router.push({
          path: "/workbench/specialCourseVideo",
          query: {
            id,
          },
        });
      }
    },

    /**
     * 网络相关
     */
    //课程列表
    async getCoursePage() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personnelLevelId: this.personnelLevelId,
        projectLabelId: this.projectLabelId,
        sort: this.sort,
        structureId: this.structureId,
        topic: this.topic,
      };
      const res = await coursePage(data);
      if (res.code == 0) {
        this.listDataFlag = false;
        this.total = res.data.total;
        this.totalPage = res.data.pages;
        // this.courseList = [...this.courseList, ...res.data.records];
        // this.courseList = res.data.records;
        this.courseList = this.courseList.concat(res.data.records)
      } else {
        this.listDataFlag = false;
        this.$message.error(res.message);
      }

    },

    //获取更多课程
    // async getCourseMorePage() {
    //   let data = {
    //     pageNum: this.pageNum,
    //     pageSize: this.pageSize,
    //     personnelLevelId: this.personnelLevelId,
    //     projectLabelId: this.projectLabelId,
    //     sort: this.sort,
    //     structureId: this.structureId,
    //     topic: this.topic,
    //   };
    //   const res = await coursePage(data);
    //   if (res.code == 0) {
    //     this.listDataFlag = false;
    //     // this.courseList = res.data.list;
    //     this.total = res.data.total;
    //     this.totalPage = res.data.pages;
    //     // console.log(this.courseList);
    //     this.courseList = [...this.courseList, ...res.data.records];
    //   } else {
    //     this.listDataFlag = false;
    //     this.$message.error("res.message");
    //   }

    //   console.log('课程列表标记', this.listDataFlag);
    // },

    // 课程数量
    async getCourseNumber() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        personnelLevelId: this.personnelLevelId,
        projectLabelId: this.projectLabelId,
        sort: this.sort,
        structureId: this.structureId,
        topic: this.topic,
      };
      const res = await courseNumber(data);
      if (res.code == 0) {
        this.courseNumInfo = res.data;
      } else {
        this.$message.error(res.message);
      }
    },
    // 学员级别
    async getPersonnelLevelList() {
      const res = await personnelLevelList();
      if (res.code == 0) {
        // console.log(res);
        this.studentLevel = res.data;
      } else {
        this.$message.error("res.message");
      }
    },

    async getProjectLabelList() {
      const res = await projectLabelList(1);
      if (0 == res.code) {
        res.data.forEach((item) => {
          let tmp = {
            id: item.projectLabelId,
            title: item.projectLabelName,
          };

          this.projectLabel.push(tmp);
        });
      }
      console.log("标签列表", res);
    },
    // 车间列表
    async getStructureList() {
      const res = await structureList();
      if (res.code == 0) {
        console.log(res);
        this.structure = res.data;
      } else {
        this.$message.error("res.message");
      }
    },

    handleCurrentChange(e) {
      this.pageNum = e;
      this.getCoursePage();
    },
  },
};
</script>
<style scoped lang="scss">
.qqq{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  color: #ffffff;
}
.look-num-q{
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  .look-num{
    bottom: 8px;
    position: absolute;
    left: 16px;
    font-size: 14px;
    font-weight: 500;
  }
  
}
.course-filter {
 
  .el-header {
    padding: 0;
    height: 48px;
    background-color: #eee;
    line-height: 48px;
    border-bottom: 1px solid #eee;
    display: flex;
    .cur-nav {
      // width: 181px;
      padding: 0 56px 0 27px;
      height: 100%;
      background: #f8f9fa;
      border-radius: 8px 8px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      color: #1e1e1e;
      position: relative;
      img {
        width: 30px;
        margin-right: 10px;
      }
      i {
        position: absolute;
        right: 8px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.4);
        cursor: pointer;
      }
    }
  }
  .el-main {
    padding: 0;
    background-color: #fff;
    padding-bottom: 60px;
    .filter-con-w {
    
      .filter-icon {
        padding: 8px 24px 11px;
       
        .filter-h {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: 500;
          padding-left: 8px;
          color: #2189f3;
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            margin-left: 5px;
            font-weight: 600;
          }
        }
        .filter-b {
          .filter-classify-lis {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #1e1e1e;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .filter-title {
              font-weight: 800;
              margin-right: 24px;
              padding-left: 8px;
            }
            .filter-icon-txt {
              font-weight: 500;
              margin-right: 24px;
              cursor: pointer;
            }
            .cur {
              color: #2189f3;
            }
          }
          .filter-sort {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #1e1e1e;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .sort-title {
              font-weight: 800;
              margin-right: 24px;
              padding-left: 8px;
            }
            .sort-icon {
              display: flex;
              div {
                display: flex;
                align-items: center;
                margin-right: 10px;
                cursor: pointer;
                span {
                  font-weight: 500;
                }
                i {
                  color: #aaa;
                }
              }
            }
          }
        }
      }
    }
    .video-detail {
      padding-top: 11px;
      background-color: #eee;
        overflow: auto;
      .video-detail-h {
       
        // height: 38px;
        // line-height: 38px;
        height: 24px;
        line-height: 24px;
        padding: 13px 0 8px 24px;
        background-color: #fff;
        font-size: 14px;
        font-weight: 500;
        color: #2189f3;
        span {
          padding: 0 8px;
        }
      }
      .video-lis {
        padding: 0 24px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        .video-item {
          // height: 365px;
          width: 24%;
          margin-right: calc(4% / 3);
          margin-top: 20px;
          box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          border-radius: 16px;
          overflow: hidden;
          &:hover {
            box-shadow: 0px 4px 8px 4px rgba(43, 140, 239, 0.12);
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          .video-img {
            height: 248px;
            overflow: hidden;
            position: relative;
            .el-image {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: auto;
            }
            .look-num-w {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              z-index: 9;
              background-color: rgba(0, 0, 0, 0.5);
              .look-num {
                position: absolute;
                left: 16px;
                bottom: 8px;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
          .video-txt {
            padding: 0 16px;
            height: 131px;
            overflow: hidden;
            .video-h {
              margin-top: 16px;
              display: flex;
              align-items: flex-start;
              .video-h-txt {
                
                font-size: 16px;
                font-weight: 800;
                color: #1e1e1e;
                margin-right: 9px;
                flex: 1;
                height: 50px;
               
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            
              }
              .video-ico {
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                padding: 4px 18px;
                background: linear-gradient(47deg, #03c9fd 0%, #0a93fb 100%);
                border-radius: 4px 4px 4px 0px;
              }
              .topic-ico {
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px 4px 4px 0px;
                padding: 4px 18px;
                background: #2189f3;
                color: #ffffff;
              }
            }
            .video-intro {
              // margin-top: 8px;
              // margin-bottom: 14px;
              // padding-top: 8px;
              font-size: 14px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.8);
              overflow: hidden;
              text-overflow: ellipsis;
              // line-height: 24px;
              -webkit-line-clamp: 2; /*想省略几行就写几*/
              -webkit-box-orient: vertical;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              line-height: 25px;
            }
          }
        }
      }

      .pagenation {
        background: #fff;
      }
    }
  }
}
</style>
