import {request} from '@/api/common/request.js';

const coursePageUrl = "/base/baseCourse/coursePage";//课程列表
const courseNumberUrl = "/user/baseCourse/courseNumber";//课程数量
const personnelLevelListUrl = "/base/baseProjectLabel/personnelLevelList";//学员级别
const structureListUrl = "/base/baseProjectLabel/structureList";//车间列表
const projectLabelListUrl = "/base/baseProjectLabel/projectLabelList/";//标签类型

//标签类型
export function projectLabelList(type){
  return request({
      url: projectLabelListUrl + type,
      method: 'get',
    });
}

//课程列表
export function coursePage(data){
  return request({
    url:coursePageUrl,
    method:'get',
    data
  })
}
//课程数量
export function courseNumber(data){
  return request({
    url:courseNumberUrl,
    method:'get',
    data,
  })
}
//学员级别
export function personnelLevelList(){
  return request({
    url:personnelLevelListUrl,
    method:'get',
  })
}
//车间列表
export function structureList(){
  return request({
    url:structureListUrl,
    method:'get',
  })
}
